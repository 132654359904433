import { createSlice } from '@reduxjs/toolkit';

const claimintimationSlice = createSlice({
    name: 'claimintimation',
    initialState: {
        PoliceStationDateError: '',
        PoliceStationError: '',
        FirError: '',
        InjuryError: '',
        TimeError: '',
        OtherError: '',
        PincodeError: '',
        PanError: '',
        EmailError: '',
        ColorError: '',
        AddressError: '',
        PanError: '',
        ClaimNumber1Error: '',
        ClaimNumberError: '',
        AuthoraisedperonError: '',
        PlaceError: '',
        ThirdpartydamageError: '',
        DetailsofwitnessError: '',
        Age1Error: '',
        Gender1Error: '',
        Gridname1Error: '',
        WhatcapacityError: '',
        AgeError: '',
        GenderError: '',
        GridnameError: '',
        SuvaddressError: '',
        RepaddressError: '',
        RepnameError: '',
        SpecifyError: '',
        RelationshipdriverError: '',
        NarrationError: '',
        RecorrectionwitnesscopyError: '',
        PurposeofuseError: '',
        NoofOccupantsError: '',
        WeightofgoodsError: '',
        PolicereportError: '',
        DetailexistingError: '',
        IntrestError: '',
        SurveyError: '',
        AssaingError: '',
        IdvError: '',
        PolicyError: '',
        PolicyendoresError: '',
        StatusError: '',
        DriverwhatsappError: '',
        DriveremailError: '',
        RepemailError: '',
        Status2Error: '',
        Status3Error: '',
        Status4Error: '',
        WhatsappError: '',
        TypeofvehicleError: '',
        CustomernameError: '',
        CustomermobileError: '',
        CustomeremailError: '',
        AccidentonError: '',
        MakeError: '',
        ModelError: '',
        InsurerError: '',
        InsurerPeriodError: '',
        PancopyError: '',
        DateFromError: '',
        DateFrom2Error: '',
        Date1Error: '',
        DateError: '',
        GridcontactnumberError: '',
        RepcontactnumberError: '',
        GridmailError: '',
        GridgeolocationError: '',
        PersonalemailError: '',
        Personalphone1Error: '',
        Personalphone2Error: '',
        NameError: '',
        NameDobError: '',
        HypothecationError: '',
        LicenceError: '',
        LicencevalidError: '',
        GstvalidError: '',
        SuvremarksoneError: '',
        SuvremarkstwoError: '',
        RepremarksoneError: '',
        RepremarkstwoError: '',
        PolicyexcessError: '',
        EngineError: '',
        ChassisError: '',
        OdometerError: '',
        YearError: '',
        ClaimError: '',
        VehicleregumberError: '',
        InjuryType1Error: '',
        Natureofinjury1Error: '',
        InjuryTypeError: '',
        NatureofinjuryError: '',
        PermittedusersError: '',
        FuelError: '',
        estimateCopyError: '',
        estimateAmountError: '',
        data_tom: [],
        data_tom_2: [],
        data_lab: [],
        data_val: [],
    },
    reducers: {
        set_data_lab: (state, action) => {
            console.log('data received to redux1 is =>> ', action.payload)
            state.data_lab = action.payload
        },
        set_data_val: (state, action) => {
            console.log('data received to redux1 is =>> ', action.payload)
            state.data_val = action.payload
        },
        set_data_tom: (state, action) => {
            console.log("data received to redux1 is =>> ",action.payload);
            state.data_tom = action.payload;
        },
        set_data_tom_2: (state, action) => {
            console.log("data received to redux2 is =>> ",action.payload);
            state.data_tom_2 = action.payload;
        },
        setPoliceStationDateError: (state, action) => {
            state.PoliceStationDateError = { name: action.payload };
        },
        clearPoliceStationDateError: (state) => {
            state.PoliceStationDateError = {};
        },
        setPoliceStationError: (state, action) => {
            state.PoliceStationError = { name: action.payload };
        },
        clearPoliceStationError: (state) => {
            state.PoliceStationError = {};
        },
        setestimateAmountError: (state, action) => {
            state.estimateAmountError = { name: action.payload };
        },
        clearestimateAmountError: (state) => {
            state.estimateAmountError = {};
        },
        setestimateCopyError: (state, action) => {
            state.estimateCopyError = { name: action.payload };
        },
        clearestimateCopyError: (state) => {
            state.estimateCopyError = {};
        },
        setFirError: (state, action) => {
            state.FirError = { name: action.payload };
        },
        clearFirError: (state) => {
            state.FirError = {};
        },
        
        setTimeError: (state, action) => {
            state.TimeError = { name: action.payload };
        },
        clearTimeError: (state) => {
            state.TimeError = {};
        }
        ,setOtherError: (state, action) => {
            state.OtherError = { name: action.payload };
        },
        clearOtherError: (state) => {
            state.OtherError = {};
        }, setAddressError: (state, action) => {
            state.AddressError = { name: action.payload };
        },
        clearAddressError: (state) => {
            state.AddressError = {};
        }, setEmailError: (state, action) => {
            state.EmailError = { name: action.payload };
        },
        clearEmailError: (state) => {
            state.EmailError = {};
        },
        setClaimNumber1Error: (state, action) => {
            state.ClaimNumber1Error = { name: action.payload };
        },
        clearClaimNumber1Error: (state) => {
            state.ClaimNumber1Error = {};
        },
        setClaimNumberError: (state, action) => {
            state.ClaimNumberError = { name: action.payload };
        },
        clearClaimNumberError: (state) => {
            state.ClaimNumberError = {};
        },
        setAuthoraisedperonError: (state, action) => {
            state.AuthoraisedperonError = { name: action.payload };
        },
        clearAuthoraisedperonError: (state) => {
            state.AuthoraisedperonError = {};
        },
        setPlaceError: (state, action) => {
            state.PlaceError = { name: action.payload };
        },
        clearPlaceError: (state) => {
            state.PlaceError = {};
        },
        setThirdpartydamageError: (state, action) => {
            state.ThirdpartydamageError = { name: action.payload };
        },
        clearThirdpartydamageError: (state) => {
            state.ThirdpartydamageError = {};
        },
        setDetailsofwitnessError: (state, action) => {
            state.DetailsofwitnessError = { name: action.payload };
        },
        clearDetailsofwitnessError: (state) => {
            state.DetailsofwitnessError = {};
        },
        setNatureofinjury1Error: (state, action) => {
            state.Natureofinjury1Error = { name: action.payload };
        },
        clearNatureofinjury1Error: (state) => {
            state.Natureofinjury1Error = {};
        },
        setInjuryError: (state, action) => {
            state.InjuryError = { name: action.payload };
        },
        clearInjuryError: (state) => {
            state.InjuryError = {};
        },
        setInjuryType1Error: (state, action) => {
            state.InjuryType1Error = { name: action.payload };
        },
        clearInjuryType1Error: (state) => {
            state.InjuryType1Error = {};
        },
        setGender1Error: (state, action) => {
            state.Gender1Error = { name: action.payload };
        },
        clearGender1Error: (state) => {
            state.Gender1Error = {};
        },
        setAge1Error: (state, action) => {
            state.Age1Error = { name: action.payload };
        },
        clearAge1Error: (state) => {
            state.Age1Error = {};
        },
        setGridname1Error: (state, action) => {
            state.Gridname1Error = { name: action.payload };
        },
        clearGridname1Error: (state) => {
            state.Gridname1Error = {};
        },
        setNatureofinjuryError: (state, action) => {
            state.NatureofinjuryError = { name: action.payload };
        },
        clearNatureofinjuryError: (state) => {
            state.NatureofinjuryError = {};
        },
        setInjuryTypeError: (state, action) => {
            state.InjuryTypeError = { name: action.payload };
        },
        clearInjuryTypeError: (state) => {
            state.InjuryTypeError = {};
        },
        setWhatcapacityError: (state, action) => {
            state.WhatcapacityError = { name: action.payload };
        },
        clearWhatcapacityError: (state) => {
            state.WhatcapacityError = {};
        },
        setGenderError: (state, action) => {
            state.GenderError = { name: action.payload };
        },
        clearGenderError: (state) => {
            state.GenderError = {};
        },
        setAgeError: (state, action) => {
            state.AgeError = { name: action.payload };
        },
        clearAgeError: (state) => {
            state.AgeError = {};
        },
        setGridnameError: (state, action) => {
            state.GridnameError = { name: action.payload };
        },
        clearGridnameError: (state) => {
            state.GridnameError = {};
        },
        setSuvaddressError: (state, action) => {
            state.SuvaddressError = { name: action.payload };
        },
        clearSuvaddressError: (state) => {
            state.SuvaddressError = {};
        },
        setRepaddressError: (state, action) => {
            state.RepaddressError = { name: action.payload };
        },
        clearRepaddressError: (state) => {
            state.RepaddressError = {};
        },
        setRepnameError: (state, action) => {
            state.RepnameError = { name: action.payload };
        },
        clearRepnameError: (state) => {
            state.RepnameError = {};
        },
        setSpecifyError: (state, action) => {
            state.SpecifyError = { name: action.payload };
        },
        clearSpecifyError: (state) => {
            state.SpecifyError = {};
        },
        setRelationshipdriverError: (state, action) => {
            state.RelationshipdriverError = { name: action.payload };
        },
        clearRelationshipdriverError: (state) => {
            state.RelationshipdriverError = {};
        },
        setNarrationError: (state, action) => {
            state.NarrationError = { name: action.payload };
        },
        clearNarrationError: (state) => {
            state.NarrationError = {};
        },
        setRecorrectionwitnesscopyError: (state, action) => {
            state.RecorrectionwitnesscopyError = { name: action.payload };
        },
        clearRecorrectionwitnesscopyError: (state) => {
            state.RecorrectionwitnesscopyError = {};
        },
        setPurposeofuseError: (state, action) => {
            state.PurposeofuseError = { name: action.payload };
        },
        clearPurposeofuseError: (state) => {
            state.PurposeofuseError = {};
        },
        setNoofOccupantsError: (state, action) => {
            state.NoofOccupantsError = { name: action.payload };
        },
        clearNoofOccupantsError: (state) => {
            state.NoofOccupantsError = {};
        },
        setWeightofgoodsError: (state, action) => {
            state.WeightofgoodsError = { name: action.payload };
        },
        clearWeightofgoodsError: (state) => {
            state.WeightofgoodsError = {};
        },
       
        setPolicereportError: (state, action) => {
            state.PolicereportError = { name: action.payload };
        },
        clearPolicereportError: (state) => {
            state.PolicereportError = {};
        },
        setDetailexistingError: (state, action) => {
            state.DetailexistingError = { name: action.payload };
        },
        clearDetailexistingError: (state) => {
            state.DetailexistingError = {};
        },
        setIntrestError: (state, action) => {
            state.IntrestError = { name: action.payload };
        },
        clearIntrestError: (state) => {
            state.IntrestError = {};
        },
        setVehicleregumberError: (state, action) => {
            state.VehicleregumberError = { name: action.payload };
        },
        clearVehicleregumberError: (state) => {
            state.VehicleregumberError = {};
        },
        setSurveyError: (state, action) => {
            state.SurveyError = { name: action.payload };
        },
        clearSurveyError: (state) => {
            state.SurveyError = {};
        },
        setAssaingError: (state, action) => {
            state.AssaingError = { name: action.payload };
        },
        clearAssaingError: (state) => {
            state.AssaingError = {};
        },
        setClaimError: (state, action) => {
            state.ClaimError = { name: action.payload };
        },
        clearClaimError: (state) => {
            state.ClaimError = {};
        },
        setColorError: (state, action) => {
            state.ColorError = { name: action.payload };
        },
        clearColorError: (state) => {
            state.ColorError = {};
        },
        setYearError: (state, action) => {
            state.YearError = { name: action.payload };
        },
        clearYearError: (state) => {
            state.YearError = {};
        },
        setOdometerError: (state, action) => {
            state.OdometerError = { name: action.payload };
        },
        clearOdometerError: (state) => {
            state.OdometerError = {};
        },
        setChassisError: (state, action) => {
            state.ChassisError = { name: action.payload };
        },
        clearChassisError: (state) => {
            state.ChassisError = {};
        },
        setEngineError: (state, action) => {
            state.EngineError = { name: action.payload };
        },
        clearEngineError: (state) => {
            state.EngineError = {};
        },
        setHypothecationError: (state, action) => {
            state.HypothecationError = { name: action.payload };
        },
        clearHypothecationError: (state) => {
            state.HypothecationError = {};
        },
        setPolicyendoresError: (state, action) => {
            state.PolicyendoresError = { name: action.payload };
        },
        clearPolicyendoresError: (state) => {
            state.PolicyendoresError = {};
        },
        setPolicyexcessError: (state, action) => {
            state.PolicyexcessError = { name: action.payload };
        },
        clearPolicyexcessError: (state) => {
            state.PolicyexcessError = {};
        },
        setIdvError: (state, action) => {
            state.IdvError = { name: action.payload };
        },
        clearIdvError: (state) => {
            state.IdvError = {};
        },
        setPolicyError: (state, action) => {
            state.PolicyError = { name: action.payload };
        },
        clearPolicyError: (state) => {
            state.PolicyError = {};
        },
        setLicencevalidError: (state, action) => {
            state.LicencevalidError = { name: action.payload };
        },
        clearLicencevalidError: (state) => {
            state.LicencevalidError = {};
        },
        setLicenceError: (state, action) => {
            state.LicenceError = { name: action.payload };
        },
        clearLicenceError: (state) => {
            state.LicenceError = {};
        },
        setGstvalidError: (state, action) => {
            state.GstvalidError = { name: action.payload };
        },
        clearGstvalidError: (state) => {
            state.GstvalidError = {};
        },       
        setSuvremarksoneError: (state, action) => {
            state.SuvremarksoneError = { name: action.payload };
        },
        clearSuvremarksoneEError: (state) => {
            state.SuvremarksoneError = {};
        },
        setSuvremarkstwoError: (state, action) => {
            state.SuvremarkstwoError = { name: action.payload };
        },
        clearSuvremarkstwoEError: (state) => {
            state.SuvremarkstwoError = {};
        },
        setRepremarksoneError: (state, action) => {
            state.RepremarksoneError = { name: action.payload };
        },
        clearRepremarksoneEError: (state) => {
            state.RepremarksoneError = {};
        },
        setRepremarkstwoError: (state, action) => {
            state.RepremarkstwoError = { name: action.payload };
        },
        clearRepremarkstwoEError: (state) => {
            state.RepremarkstwoError = {};
        },
        setDriveremailError: (state, action) => {
            state.DriveremailError = { name: action.payload };
        },
        clearDriveremailError: (state) => {
            state.DriveremailError = {};
        },
        setRepemailError: (state, action) => {
            state.RepemailError = { name: action.payload };
        },
        clearRepemailError: (state) => {
            state.RepemailError = {};
        },
        setDriverwhatsappError: (state, action) => {
            state.DriverwhatsappError = { name: action.payload };
        },
        clearDriverwhatsappError: (state) => {
            state.DriverwhatsappError = {};
        },
        setPersonalemailError: (state, action) => {
            state.PersonalemailError = { name: action.payload };
        },
        clearPersonalemailError: (state) => {
            state.PersonalemailError = {};
        },
        setPersonalphone1Error: (state, action) => {
            state.Personalphone1Error = { name: action.payload };
        },
        clearPersonalphone1Error: (state) => {
            state.Personalphone1Error = {};
        },
        setPersonalphone2Error: (state, action) => {
            state.Personalphone2Error = { name: action.payload };
        },
        clearPersonalphone2Error: (state) => {
            state.Personalphone2Error = {};
        },
        setNameError: (state, action) => {
            state.NameError = { name: action.payload };
        },
        clearNameError: (state) => {
            state.NameError = {};
        },
       
        setNameDobError: (state, action) => {
            state.NameDobError = { name: action.payload };
        },
        clearNameDobError: (state) => {
            state.NameDobError = {};
        },
        setStatusError: (state, action) => {
            state.StatusError = { name: action.payload };
        },
        clearStatusError: (state) => {
            state.StatusError = {};
        },
        setStatus2Error: (state, action) => {
            state.Status2Error = { name: action.payload };
        },
        clearStatus2Error: (state) => {
            state.Status2Error = {};
        },
        setStatus3Error: (state, action) => {
            state.Status3Error = { name: action.payload };
        },
        clearStatus3Error: (state) => {
            state.Status3Error = {};
        },
        setStatus4Error: (state, action) => {
            state.Status4Error = { name: action.payload };
        },
        clearStatus4Error: (state) => {
            state.Status4Error = {};
        },
        setWhatsappError: (state, action) => {
            state.WhatsappError = { name: action.payload };
        },
        clearWhatsappError: (state) => {
            state.WhatsappError = {};
        },
        setTypeofvehicleError: (state, action) => {
            state.TypeofvehicleError = { name: action.payload };
        },
        clearTypeofvehicleError: (state) => {
            state.TypeofvehicleError = {};
        },
        setCustomernameError: (state, action) => {
            state.CustomernameError = { name: action.payload };
        },
        clearCustomernameError: (state) => {
            state.CustomernameError = {};
        },
        setCustomermobileError: (state, action) => {
            state.CustomermobileError = { name: action.payload };
        },
        clearCustomermobileError: (state) => {
            state.CustomermobileError = {};
        },
        setCustomeremailError: (state, action) => {
            state.CustomeremailError = { name: action.payload };
        },
        clearCustomeremailError: (state) => {
            state.CustomeremailError = {};
        },
        setAccidentonError: (state, action) => {
            state.AccidentonError = { name: action.payload };
        },
        clearAccidentonError: (state) => {
            state.AccidentonError = {};
        },
        setMakeError: (state, action) => {
            state.MakeError = { name: action.payload };
        },
        clearMakeError: (state) => {
            state.MakeError = {};
        },
        setModelError: (state, action) => {
            state.ModelError = { name: action.payload };
        },
        clearModelError: (state) => {
            state.ModelError = {};
        },
        setInsurerPeriodError: (state, action) => {
            state.InsurerPeriodError = { name: action.payload };
        },
        clearInsurerPeriodError: (state) => {
            state.InsurerPeriodError = {};
        },
        setInsurerError: (state, action) => {
            state.InsurerError = { name: action.payload };
        },
        clearInsurerError: (state) => {
            state.InsurerError = {};
        },
        setPancopyError: (state, action) => {
            state.PancopyError = { name: action.payload };
        },
        clearPancopyError: (state) => {
            state.PancopyError = {};
        },
        setDateFromError: (state, action) => {
            state.DateFromError = { name: action.payload };
        },
        clearDateFromError: (state) => {
            state.DateFromError = {};
        },
        setDateFrom2Error: (state, action) => {
            state.DateFrom2Error = { name: action.payload };
        },
        clearDateFrom2Error: (state) => {
            state.DateFrom2Error = {};
        },
        setDate1Error: (state, action) => {
            state.Date1Error = { name: action.payload };
        },
        clearDate1Error: (state) => {
            state.Date1Error = {};
        },
        setDateError: (state, action) => {
            state.DateError = { name: action.payload };
        },
        clearDateError: (state) => {
            state.DateError = {};
        },
        setGridcontactnumberError: (state, action) => {
            state.GridcontactnumberError = { name: action.payload };
        },
        setRepcontactnumberError: (state, action) => {
            state.RepcontactnumberError = { name: action.payload };
        },
        clearGridcontactnumberError: (state) => {
            state.GridcontactnumberError = {};
        },
        clearRepcontactnumberError: (state) => {
            state.RepcontactnumberError = {};
        },
        setGridmailError: (state, action) => {
            state.GridmailError = { name: action.payload };
        },
        clearGridmailError: (state) => {
            state.GridmailError = {};
        },
        setGridgeolocationError: (state, action) => {
            state.GridgeolocationError = { name: action.payload };
        },
        clearGridgeolocationError: (state) => {
            state.GridgeolocationError = {};
        },
        setPermittedusersError: (state, action) => {
            state.PermittedusersError = { name: action.payload };
        },
        clearPermittedusersError: (state) => {
            state.PermittedusersError = {};
        },
         setFuelError: (state, action) => {
            state.FuelError = { name: action.payload };
        },
        clearFuelError: (state) => {
            state.FuelError = {};
        },
        
         setPincodeError: (state, action) => {
            state.PincodeError = { name: action.payload };
        },
        clearPincodeError: (state) => {
            state.PincodeError = {};
        },
    }
});

export const {
    setPincodeError,
    clearPincodeError,
    setEmailError,
    clearEmailError,
    setClaimNumber1Error,
    clearClaimNumber1Error,
    setClaimNumberError,
    clearClaimNumberError,
    setAuthoraisedperonError,
    clearAuthoraisedperonError,
    setPlaceError,
    clearPlaceError,
    setThirdpartydamageError,
    clearThirdpartydamageError,
    setDetailsofwitnessError,
    clearDetailsofwitnessError,
    setNatureofinjury1Error,
    clearNatureofinjury1Error,
    setInjuryError,
    clearInjuryError,
    setInjuryType1Error,
    clearInjuryType1Error,
    setGender1Error,
    clearGender1Error,
    setAge1Error,
    clearAge1Error,
    setGridname1Error,
    clearGridname1Error,
    setNatureofinjuryError,
    clearNatureofinjuryError,
    setInjuryTypeError,
    clearInjuryTypeError,
    setWhatcapacityError,
    clearWhatcapacityError,
    setGenderError,
    clearGenderError,
    setAgeError,
    clearAgeError,
    setGridnameError,
    clearGridnameError,
    setSuvaddressError,
    clearSuvaddressError,
    setRepaddressError,
    clearRepaddressError,
    setRepnameError,
    clearRepnameError,
    setSpecifyError,
    clearSpecifyError,
    setRelationshipdriverError,
    clearRelationshipdriverError,
    setNarrationError,    
    clearNarrationError,
    setRecorrectionwitnesscopyError,
    clearRecorrectionwitnesscopyError,
    setPurposeofuseError,
    clearPurposeofuseError,
    setNoofOccupantsError,
    clearNoofOccupantsError,
    setWeightofgoodsError,
    clearWeightofgoodsError,
    setPolicereportError,
    clearPolicereportError,
    setDetailexistingError,
    clearDetailexistingError,
    setIntrestError,
    clearIntrestError,
    setVehicleregumberError,
    clearVehicleregumberError,
    setSurveyError,
    clearSurveyError,
    setAssaingError,
    clearAssaingError,
    setClaimError,
    clearClaimError,
    setColorError,
    clearColorError,  
    setAddressError,
    clearAddressError,    
    setYearError,
    clearYearError,
    setOdometerError,
    clearOdometerError,
    setChassisError,
    clearChassisError,
    setEngineError,
    clearEngineError,
    setHypothecationError,
    clearHypothecationError,
    setPolicyendoresError,
    clearPolicyendoresError,
    setPolicyexcessError,
    clearPolicyexcessError,
    setIdvError,
    clearIdvError,
    setInsurerPeriodError,
    clearInsurerPeriodError,
    setPolicyError,
    clearPolicyError,
    setLicencevalidError,
    setGstvalidError,
    setSuvremarksoneError,
    setSuvremarkstwoError,
    setRepremarksoneError,
    setRepremarkstwoError,
    clearLicencevalidError,
    clearGstvalidError,
    clearSuvremarksoneEError,
    clearSuvremarkstwoEError,
    clearRepremarksoneEError,
    clearRepremarkstwoEError,
    setLicenceError,
    clearLicenceError,
    setDriveremailError,
    clearDriveremailError,
    setRepemailError,
    clearRepemailError,
    setDriverwhatsappError,
    clearDriverwhatsappError,
    setStatus2Error,
    clearStatus2Error,
    setStatus3Error,
    clearStatus3Error,
    setStatus4Error,
    clearStatus4Error,
    setDateError,
    clearDateError,
    setDate1Error,
    clearDate1Error,
    setDateFrom2Error,
    clearDateFrom2Error,
    setPersonalemailError,
    clearPersonalemailError,
    setPersonalphone1Error,
    clearPersonalphone1Error,
    setPersonalphone2Error,
    clearPersonalphone2Error,
    setNameError,
    setNameDobError,
    clearNameDobError,
    clearNameError,
    setInsurerError,
    clearInsurerError,
    setPancopyError,
    clearPancopyError,
    setDateFromError,
    clearDateFromError,
    setGridcontactnumberError,
    setRepcontactnumberError,
    clearGridcontactnumberError,
    clearRepcontactnumberError,
    setGridmailError,
    clearGridmailError,
    setGridgeolocationError,
    clearGridgeolocationError,
    setPermittedusersError,
    clearPermittedusersError,
    setFuelError,
    clearFuelError,  
    setFirError,
    clearFirError,
    setModelError,
    clearModelError,
    setMakeError,
    clearMakeError,
    setAccidentonError,
    clearAccidentonError,
    setCustomeremailError,
    clearCustomeremailError,
    setCustomermobileError,
    clearCustomermobileError,
    setCustomernameError,
    clearCustomernameError,
    setTypeofvehicleError,
    clearTypeofvehicleError,
    setWhatsappError,
    clearWhatsappError,
    setStatusError,
    clearStatusError,
    setTimeError,
    clearTimeError,
    setPanError,
    clearPanError,
    setOtherError,
    clearOtherError,
    setestimateAmountError,
    clearestimateAmountError,
    setestimateCopyError,
    clearestimateCopyError,
    setPoliceStationError,
    clearPoliceStationError,
    setPoliceStationDateError,
    clearPoliceStationDateError,
    set_data_tom,
    set_data_lab,
    set_data_val,
    set_data_tom_2
} = claimintimationSlice.actions;

export default claimintimationSlice.reducer;
