import { createSlice } from '@reduxjs/toolkit';

const repairerSlice = createSlice({
  name: 'repairer',
  initialState: {
    NameError: '',
    AddressError: '',
    CityError: '',
    StateError: '',
    ZoneError: '',
    CountryError: '',
    PincodeError: '',
    GstnumberError: '',
    GstcopyError: '',
    PancardError: '',
    PancopyError: '',
    GridNameError: {},
    GridcontactnumberError: {},
    GridmailError: {},
    GridgeolocationError: '',
    PermittedusersError: ''
  },
  reducers: {
    setNameError: (state, action) => {
      state.NameError = { name: action.payload };
    },
    clearNameError: (state) => {
      state.NameError = {};
    },
    setAddressError: (state, action) => {
      state.AddressError = { name: action.payload };
    },
    clearAddressError: (state) => {
      state.AddressError = {};
    },
    setCityError: (state, action) => {
      state.CityError = { name: action.payload };
    },
    clearCityError: (state) => {
      state.CityError = {};
    },
    setStateError: (state, action) => {
      state.StateError = { name: action.payload };
    },
    clearStateError: (state) => {
      state.StateError = {};
    },
    setZoneError: (state, action) => {
      state.ZoneError = { name: action.payload };
    },
    clearZoneError: (state) => {
      state.ZoneError = {};
    },
    setCountryError: (state, action) => {
      state.CountryError = { name: action.payload };
    },
    clearCountryError: (state) => {
      state.CountryError = {};
    },
    setPincodeError: (state, action) => {
      state.PincodeError = { name: action.payload };
    },
    clearPincodeError: (state) => {
      state.PincodeError = {};
    },
    setGstnumberError: (state, action) => {
      state.GstnumberError = { name: action.payload };
    },
    clearGstnumberError: (state) => {
      state.GstnumberError = {};
    },
    setGstcopyError: (state, action) => {
      state.GstcopyError = { name: action.payload };
    },
    clearGstcopyError: (state) => {
      state.GstcopyError = {};
    },
    setPancardError: (state, action) => {
      state.PancardError = { name: action.payload };
    },
    clearPancardError: (state) => {
      state.PancardError = {};
    },
    setPancopyError: (state, action) => {
      state.PancopyError = { name: action.payload };
    },
    clearPancopyError: (state) => {
      state.PancopyError = {};
    },
    setGridNameError: (state, action) => {
      state.GridNameError[action.payload.rowId] = { name: action.payload.message };
    },
    clearGridNameError: (state, action) => {
      state.GridNameError[action.payload] = {};
    },
    setGridcontactnumberError: (state, action) => {
      state.GridcontactnumberError[action.payload.rowId] = { name: action.payload.message };
    },
    clearGridcontactnumberError: (state, action) => {
      state.GridcontactnumberError[action.payload] = {};
    },
    setGridmailError: (state, action) => {
      state.GridmailError[action.payload.rowId] = { name: action.payload.message };
    },
    clearGridmailError: (state, action) => {
      state.GridmailError[action.payload] = {};
    },
    setGridgeolocationError: (state, action) => {
      state.GridgeolocationError = { name: action.payload };
    },
    clearGridgeolocationError: (state, action) => {
      state.GridgeolocationError = {};
    },
    setPermittedusersError: (state, action) => {
      state.PermittedusersError = { name: action.payload };
    },
    clearPermittedusersError: (state) => {
      state.PermittedusersError = {};
    }
  }
});

export const {
  setPancardError,
  clearPancardError,
  setPancopyError,
  clearPancopyError,
  setGridNameError,
  clearGridNameError,
  setGridcontactnumberError,
  clearGridcontactnumberError,
  setGridmailError,
  clearGridmailError,
  setGridgeolocationError,
  clearGridgeolocationError,
  setPermittedusersError,
  clearPermittedusersError,
  setGstcopyError,
  clearGstcopyError,
  setGstnumberError,
  clearGstnumberError,
  setPincodeError,
  clearPincodeError,
  setCountryError,
  clearCountryError,
  setZoneError,
  clearZoneError,
  setStateError,
  clearStateError,
  setCityError,
  clearCityError,
  setAddressError,
  clearAddressError,
  setNameError,
  clearNameError
} = repairerSlice.actions;

export default repairerSlice.reducer;
