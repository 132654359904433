import React, { useState, useEffect } from 'react'
import Route from './routes/index'
import Loader from './component/loader/index'

const ErrorBoundary = ({ children }) => {
  const [hasError, setHasError] = useState(false)

  useEffect(() => {
    const handleError = (error, errorInfo) => {
      console.error('Error caught by error boundary:', error, errorInfo)
      setHasError(true)
    }
    window.addEventListener('error', handleError)
    return () => {
      window.removeEventListener('error', handleError)
    }
  }, [])
  if (hasError) {
    return <p>Something went wrong. Please refresh the page.</p>
  }
  return children
}

function App() {
  return (
    <>
      <ErrorBoundary>
        <Route />
      </ErrorBoundary>
      <Loader />
    </>
  )
}

export default App
