import { createSlice } from '@reduxjs/toolkit';

const prilim = createSlice({
    name: 'prilim',
    initialState: {
        driver_name:"",
        driver_dob:"",
        license_number:"",
        license_upto:"",
        pl_excess:"",
        pl_endorse:"",
        period_from:"",
        period_to:"",
        policy_number:"",
        engine_no:"",
        chassis_no:"",
        manufacture:"",
        fuel:"",
        hypo:"",
        colour:"",
        odo:"",
    },
    reducers: {
        set_driver_dob: (state, action) => {
          state.driver_dob = action.payload ;
        },
        set_driver_info: (state, action) => {
          state.driver_name = action.payload ;
        },
        set_driver_lic: (state, action) => {
          state.license_number = action.payload ;
        },
        set_driver_lic_upto: (state, action) => {
          state.license_upto = action.payload ;
        },
        set_policy_number: (state, action) => {
          state.policy_number = action.payload ;
        },
        set_period_from: (state, action) => {
          state.period_from = action.payload ;
        },
        set_period_to: (state, action) => {
          state.period_to = action.payload ;
        },
        set_pll_insurer: (state, action) => {
          state.insurer_name = action.payload ;
        },
        set_pl_excess: (state, action) => {
          state.pl_excess = action.payload ;
        },
        set_pl_endorse: (state, action) => {
          state.pl_endorse = action.payload ;
        },
        set_engine_no: (state, action) => {
          state.engine_no = action.payload ;
        },
        set_chassis_no: (state, action) => {
          state.chassis_no = action.payload ;
        },
        set_manufacture: (state, action) => {
          state.manufacture = action.payload ;
        },
        set_fuel: (state, action) => {
          state.fuel = action.payload ;
        },
        set_hypo: (state, action) => {
          state.hypo = action.payload ;
        },
        set_colour: (state, action) => {
          state.colour = action.payload ;
        },
        set_odo: (state, action) => {
          state.odo = action.payload ;
        }, resetPrilimSet: (state) => {
          return {
            driver_name: "",
            driver_dob: "",
            license_number: "",
            license_upto: "",
            pl_excess: "",
            pl_endorse: "",
            period_from: "",
            period_to: "",
            policy_number: "",
            engine_no: "",
            chassis_no: "",
            manufacture: "",
            fuel: "",
            hypo: "",
            colour: "",
            odo: "",
          };
        },
        
    }
});

export const {
  set_driver_dob,set_driver_info,set_driver_lic,set_driver_lic_upto,set_policy_number,set_pll_insurer,set_period_from,set_period_to,set_pl_excess,set_pl_endorse,set_engine_no,set_chassis_no,set_manufacture,set_fuel,set_hypo,set_colour,set_odo,
  resetPrilimSet, 
} = prilim.actions;

export default prilim.reducer;