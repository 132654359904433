import React, { useEffect, useRef, useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import { Row, Col } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faEye,
  faXmark,
  faGaugeHigh,
  faGraduationCap,
  faAddressBook,
  faMotorcycle, // -- client
  faMoneyCheckDollar, // -- client
  faCashRegister,
  faNewspaper,
  faPenRuler,
} from '@fortawesome/free-solid-svg-icons'
import PropTypes from 'prop-types'

const Sidebar = ({ handleMenuClick, handleToggleSidebar, isSidebarOpen }) => {
  const [isAdminOpen, setIsAdminOpen] = useState(false)
  const [isRegistrationOpen, setIsRegistrationOpen] = useState(false)
  const [isAccountOpen, setIsAccountOpen] = useState(false)
  const [isMotorOpen, setIsMotorOpen] = useState(false)

  const toggleDropdown = (dropdownType) => {
    if (dropdownType === 'admin') {
      setIsAdminOpen(!isAdminOpen)
      setIsAccountOpen(false)
      setIsRegistrationOpen(false)
      setIsMotorOpen(false)
    } else if (dropdownType === 'registration') {
      setIsAdminOpen(false)
      setIsAccountOpen(false)
      setIsMotorOpen(false)
      setIsRegistrationOpen(!isRegistrationOpen)
    } else if (dropdownType === 'accounts') {
      setIsAdminOpen(false)
      setIsRegistrationOpen(false)
      setIsAccountOpen(!isAccountOpen)
      setIsMotorOpen(false)
    } else if (dropdownType === 'motor') {
      setIsAdminOpen(false)
      setIsRegistrationOpen(false)
      setIsAccountOpen(false)
      setIsMotorOpen(!isMotorOpen)
    }
  }

  const sidebarRef = useRef()
  useEffect(() => {
    if (isSidebarOpen) {
      document.body.classList.add('sidebar-opened')
    } else {
      document.body.classList.remove('sidebar-opened')
    }
    return () => {
      document.body.classList.remove('sidebar-opened')
    }
  }, [isSidebarOpen])

  const handleMenuSelection = (menuName) => {
    handleMenuClick(menuName)
    handleToggleSidebar()
    if (menuName === 'Dashboard') {
      setIsAdminOpen(false)
      setIsRegistrationOpen(false)
      setIsAccountOpen(false)
      setIsMotorOpen(false)
    } else if (menuName.startsWith('Masters')) {
      setIsAdminOpen(true)
      setIsRegistrationOpen(false)
      setIsAccountOpen(false)
      setIsMotorOpen(false)
    } else if (menuName.startsWith('Registrations')) {
      setIsAdminOpen(false)
      setIsRegistrationOpen(true)
      setIsAccountOpen(false)
      setIsMotorOpen(false)
    } else if (menuName.startsWith('Reports')) {
      setIsAdminOpen(false)
      setIsRegistrationOpen(false)
      setIsAccountOpen(false)
      setIsMotorOpen(false)
    } else if (menuName.startsWith('Account')) {
      setIsAdminOpen(false)
      setIsRegistrationOpen(false)
      setIsAccountOpen(true)
      setIsMotorOpen(false)
    } else if (menuName.startsWith('Roles')) {
      setIsAdminOpen(false)
      setIsRegistrationOpen(false)
      setIsAccountOpen(false)
      setIsMotorOpen(false)
    } else if (menuName.startsWith('motor')) {
      setIsAdminOpen(false)
      setIsRegistrationOpen(false)
      setIsAccountOpen(false)
      setIsMotorOpen(true)
    } else if (menuName === '') {
      setIsAdminOpen(false)
      setIsRegistrationOpen(false)
      setIsAccountOpen(false)
      setIsMotorOpen(false)
    }
  }

  const handleXmarkClick = () => {
    handleToggleSidebar()
    setIsAdminOpen(false)
    setIsRegistrationOpen(false)
    setIsAccountOpen(false)
    setIsMotorOpen(false)
  }

  return (
    <div ref={sidebarRef}>
      <aside
        className={`sidenav sidebar-shadow navbar navbar-vertical navbar-expand-xs border-0 fixed-start bg-custom ${
          isSidebarOpen ? 'sidebar-open' : 'sidebar-closed'
        }`}
        id="sidenav-main"
        ref={sidebarRef}
      >
        <div className="sidenav-header">
          <i
            className="fas fa-times p-3 cursor-pointer text-secondary opacity-5 position-absolute end-0 top-0 d-none d-xl-none"
            aria-hidden="true"
            id="iconSidenav"
          ></i>
          <Row>
            <Col>
              <Link
                className="navbar-brand m-0"
                to="/admin/dashboard"
                onClick={() => handleMenuSelection('Dashboard')}
              >
                <FontAwesomeIcon
                  icon={faEye}
                  size="2xl"
                  style={{ color: '#861f41' }}
                  className="navbar-brand-img h-100 pe-2"
                />
                <span className="ms-1 font-weight-bold">ICGMS</span>
              </Link>
            </Col>
            <Col className="my-auto ">
              <FontAwesomeIcon
                icon={faXmark}
                onClick={handleXmarkClick}
                size="xl"
                className="d-none tab-show ms-5"
              />
            </Col>
          </Row>
        </div>
        <hr className="horizontal dark mt-0" />
        <div
          className="collapse navbar-collapse  w-auto "
          id="sidenav-collapse-main"
        >
          {/* Admin */}
          <ul className="navbar-nav">
            <li className="nav-item">
              <NavLink
                className="nav-link"
                to="/admin/dashboard"
                onClick={() => handleMenuSelection('Dashboard')}
              >
                <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                  <FontAwesomeIcon icon={faGaugeHigh} />
                </div>
                <span className="nav-link-text ms-1">Dashboard</span>
              </NavLink>
            </li>
            <li className="nav-item">
              <Link
                className="nav-link"
                data-toggle="collapse"
                to="#"
                aria-expanded="false"
                aria-controls="ui-basic"
                onClick={() => toggleDropdown('admin')}
              >
                <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                  <FontAwesomeIcon icon={faGraduationCap} />
                </div>
                <span className="nav-link-text ms-1">Masters </span>
                <i className="fas fa-caret-down menu-arrow text-end mt-1"></i>
              </Link>
              <div
                className={isAdminOpen ? 'collapse show' : 'collapse'}
                id="master-menu"
              >
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item active-sub">
                    <NavLink
                      className="nav-link text-dark"
                      to="/admin/vehicle"
                      onClick={() => handleMenuSelection('Vehicle')}
                    >
                      Vehicle
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      className="nav-link text-dark"
                      to="/admin/location"
                      onClick={() => handleMenuSelection('Location')}
                    >
                      Location
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      className="nav-link text-dark"
                      to="/admin/product"
                      onClick={() => handleMenuSelection('Product')}
                    >
                      Product
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      className="nav-link text-dark"
                      to="/admin/insurer"
                      onClick={() => handleMenuSelection('Insurer')}
                    >
                      Insurer
                    </NavLink>
                  </li>
                </ul>
              </div>
            </li>
            <li className="nav-item">
              <Link
                className="nav-link"
                href=""
                data-toggle="collapse"
                aria-expanded="false"
                aria-controls="ui-basic"
                onClick={() => toggleDropdown('registration')}
              >
                <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                  <FontAwesomeIcon icon={faCashRegister} />
                </div>
                <span className="nav-link-text ms-1">Registrations </span>
                <i className="fas fa-caret-down menu-arrow text-end mt-1"></i>
              </Link>
              <div
                className={isRegistrationOpen ? 'collapse show' : 'collapse'}
                id="master-menu"
              >
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item active-sub">
                    <NavLink
                      className="nav-link text-dark"
                      to="/admin/icgms"
                      onClick={() => handleMenuSelection('ICGMS Users')}
                    >
                      ICGMS Users
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      className="nav-link text-dark"
                      to="/admin/client"
                      onClick={() => handleMenuSelection('Client')}
                    >
                      Client
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      className="nav-link text-dark"
                      to="/admin/surveyor"
                      onClick={() => handleMenuSelection('Surveyor')}
                    >
                      Surveyor
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      className="nav-link text-dark"
                      to="/admin/repairer"
                      onClick={() => handleMenuSelection('Repairer')}
                    >
                      Repairer
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      className="nav-link text-dark"
                      to="/admin/insurancefinancier"
                      onClick={() => handleMenuSelection('Claim Financier')}
                    >
                      Claim Financier
                    </NavLink>
                  </li>
                </ul>
              </div>
            </li>
            <li className="nav-item">
              <NavLink
                className="nav-link  "
                to="/admin/reports"
                onClick={() => handleMenuSelection('Reports')}
              >
                <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                  <FontAwesomeIcon icon={faNewspaper} />
                </div>
                <span className="nav-link-text ms-1">Reports</span>
              </NavLink>
            </li>
            <li className="nav-item">
              <Link
                className="nav-link"
                href=""
                data-toggle="collapse"
                aria-expanded="false"
                aria-controls="ui-basic"
                onClick={() => toggleDropdown('accounts')}
              >
                <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                  <FontAwesomeIcon icon={faAddressBook} />
                </div>
                <span className="nav-link-text ms-1">Account </span>
                <i className="fas fa-caret-down menu-arrow text-end mt-1"></i>
              </Link>
              <div
                className={isAccountOpen ? 'collapse show' : 'collapse'}
                id="master-menu"
              >
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item subscript credit">
                    <NavLink
                      className="nav-link text-dark mt-3 subscriptLabel"
                      to="/admin/account/subscription"
                      onClick={() => handleMenuSelection('Subscription')}
                    >
                      Subscription
                    </NavLink>
                  </li>
                  <li className="nav-item subscript postpaid">
                    <NavLink
                      className="nav-link text-dark mt-2 subscriptLabel"
                      to="/admin/account/billing"
                      onClick={() => handleMenuSelection('Billing')}
                    >
                      Billing
                    </NavLink>
                  </li>
                </ul>
              </div>
            </li>
            <li className="nav-item">
              <NavLink
                className="nav-link  "
                to="/admin/roles"
                onClick={() => handleMenuSelection('Roles and Privileges')}
              >
                <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                  <FontAwesomeIcon icon={faPenRuler} />
                </div>
                <span className="nav-link-text ms-1">Roles and Privileges</span>
              </NavLink>
            </li>
          </ul>

          {/* Client */}
          {/* <ul className="navbar-nav">
            <li className="nav-item">
              <NavLink
                className="nav-link"
                to="/client/client/dashboard"
                onClick={() => handleMenuSelection('Dashboard')}
              >
                <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                  <FontAwesomeIcon icon={faGaugeHigh} />
                </div>
                <span className="nav-link-text ms-1">Dashboard</span>
              </NavLink>
            </li>
            <li className="nav-item">
              <Link
                className="nav-link"
                href=""
                data-toggle="collapse"
                aria-expanded="false"
                aria-controls="ui-basic"
                onClick={() => toggleDropdown('registration')}
              >
                <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                  <FontAwesomeIcon icon={faCashRegister} />
                </div>
                <span className="nav-link-text ms-1">Registrations </span>
                <i className="fas fa-caret-down menu-arrow text-end mt-1"></i>
              </Link>
              <div
                className={isRegistrationOpen ? 'collapse show' : 'collapse'}
                id="master-menu"
              >
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item active-sub">
                    <NavLink
                      className="nav-link text-dark"
                      to="/client/client/user/grid"
                      onClick={() => handleMenuSelection('Users/Team')}
                    >
                      Users/Team
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      className="nav-link text-dark"
                      to="/client/client/surveyor/grid"
                      onClick={() => handleMenuSelection('Surveyor')}
                    >
                      Surveyor
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      className="nav-link text-dark"
                      to="/client/client/repairer/grid"
                      onClick={() => handleMenuSelection('Repairer')}
                    >
                      Repairer
                    </NavLink>
                  </li>
                </ul>
              </div>
            </li>
            <li className="text-xs fw-bolder ms-4 ps-2 mt-2 text-black">
              Product
            </li>
            <li className="nav-item">
              <Link
                className="nav-link"
                data-toggle="collapse"
                href=""
                aria-expanded="false"
                aria-controls="ui-basic"
                onClick={() => toggleDropdown('motor')}
              >
                <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                  <FontAwesomeIcon icon={faMotorcycle} />
                </div>
                <span className="nav-link-text ms-1">
                  Motor Claim Insurance{' '}
                </span>
                <i className="fas fa-caret-down menu-arrow text-end mt-1"></i>
              </Link>
              <div
                className={isMotorOpen ? 'collapse show' : 'collapse'}
                id="master-menu"
              >
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item active-sub">
                    <NavLink
                      className="nav-link text-dark"
                      to="/client/client/newclaim/creation"
                      onClick={() => handleMenuSelection('Claim Registration')}
                    >
                      New Claim Registration
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      className="nav-link text-dark"
                      to="/client/client/claimverify/grid"
                      onClick={() => handleMenuSelection('Claim Verification')}
                    >
                      Claim Intimation - QC
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      className="nav-link text-dark"
                      href=""
                      onClick={() => handleMenuSelection('Claim Finalisation')}
                    >
                      Claim Finalisation
                    </NavLink>
                  </li>
                </ul>
              </div>
            </li>
            <li className="nav-item">
              <NavLink
                className="nav-link  "
                to="/client/client/reports"
                onClick={() => handleMenuSelection('Reports')}
              >
                <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                  <FontAwesomeIcon icon={faNewspaper} />
                </div>
                <span className="nav-link-text ms-1">Reports</span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className="nav-link  "
                to="/client/client/roles"
                onClick={() => handleMenuSelection('Billing')}
              >
                <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                  <FontAwesomeIcon icon={faMoneyCheckDollar} />
                </div>
                <span className="nav-link-text ms-1">Billing</span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className="nav-link  "
                to="/client/client/roles"
                onClick={() => handleMenuSelection('Roles and Privileges')}
              >
                <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                  <FontAwesomeIcon icon={faPenRuler} />
                </div>
                <span className="nav-link-text ms-1">Roles and Privileges</span>
              </NavLink>
            </li>
          </ul> */}
        </div>
      </aside>
      {isSidebarOpen && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 999,
          }}
          onClick={handleToggleSidebar}
        ></div>
      )}
    </div>
  )
}

Sidebar.propTypes = {
  handleMenuClick: PropTypes.func.isRequired,
  handleToggleSidebar: PropTypes.func.isRequired,
  isSidebarOpen: PropTypes.bool.isRequired,
}

export default Sidebar
