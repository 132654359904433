import React from 'react'
import { useSelector } from 'react-redux'
import Lottie from 'react-lottie'
import LoaderLottieFile from '../loaderLottieFile/index.json'
import Modal from 'react-bootstrap/Modal'
import '../../assets/css/custom.css'

const Loader = () => {
  // const classes = useStyles()
  const loader = useSelector((state) => state?.loader)
  const { showLoader, loaderTxt } = loader

  // Lottie file options
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: LoaderLottieFile,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  return (
    <Modal
      show={showLoader}
      // dialogClassName="loader-modal-dialog"
      contentClassName="loader-modal-body"
      // className="test"
      // style={{ borderRadius: 'none !important' }}
      className="loader-modal"
    >
      <Modal.Body>
        <div className="loaderContainer">
          <Lottie options={defaultOptions} height={80} width={80} />
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default Loader
