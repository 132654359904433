import React, { useState, useEffect } from 'react';
import { useNavigate, Outlet, useLocation } from 'react-router-dom';
import '../assets/css/custom.css';
import Sidebar from '../pages/main/includes/Sidebar';
import Header from '../pages/main/includes/Header';
import Footer from '../pages/main/includes/Footer';
import Cookies from 'js-cookie';

const MainLayout = () => {
  const [currentMenu, setCurrentMenu] = useState('Dashboard');
  const [, setLoading] = useState(true);
  const location = useLocation();
  const isCustomerFormPage = location.pathname.includes('/client/client/claimverify/print') || location.pathname.includes('/client/client/iciciform') || location.pathname.includes('/client/client/claimverify/intimation') || location.pathname.includes('/client/client/iffcoform');
  const handleMenuClick = (menuName) => {
    setCurrentMenu(menuName);
  };
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    if (!Cookies.get('pageSignature')) {
      navigate('/');
    } else {
      setLoading(false);
    }
  }, [navigate]);

  const handleToggleSidebar = () => {
    const body = document.querySelector('body');
    const mainContent = document.querySelector('.main-content');
    if (sidebarOpen) {
      body.classList.remove('g-sidenav-pinned');
      mainContent.classList.remove('overlay');
    } else {
      body.classList.add('g-sidenav-pinned');
      mainContent.classList.add('overlay');
    }
    setSidebarOpen(!sidebarOpen);
  };

  return (
    <div className=''>
      {isCustomerFormPage ? '' : <Sidebar
        handleMenuClick={handleMenuClick}
        handleToggleSidebar={handleToggleSidebar}
      />}
      <main className={isCustomerFormPage ? '' : 'main-content position-relative max-height-vh-100 h-100 border-radius-lg'}>
      {isCustomerFormPage ? '' : <Header
          currentMenu={currentMenu}
          handleToggleSidebar={handleToggleSidebar}
        />}
        <div className={isCustomerFormPage ? '' : 'container-fluid py-4'}>
          <Outlet />
        </div>
        {isCustomerFormPage ? '' : <Footer />}
      </main>
    </div>
  );
};

export default MainLayout;
