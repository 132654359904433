import { createSlice } from '@reduxjs/toolkit'
const initialState = {
  NameError: '',
  RegisterofficeError: '',
  CityError: '',
  StateError: '',
  ZoneError: '',
  PercaseError: '',
  PincodeError: '',
  GstnumberError: '',
  GstcopyError: '',
  PancardError: '',
  PancopyError: '',
  GridNameError: {},
  AadhaarcopyError: '',
  SlavendordateError: '',
  SlafromdateError: '',
  SlatodateError: '',
  AdvancepaidError: '',
  GridcontactnumberError: {},
  GridmailError: {},
  SelectproductsError: '',
  GridgeolocationError: '',
  PermittedusersError: '',
  ClientTypeError: '',
  BillingTypeError: '',
  GridCityError: {},
  GridStateError: {},
  GridZoneError: {},
  GridCountryError: {},
  GridBranchError: {},
  GridDesignationError: {},
  GridDefaultCheckError: {},
}

const clientSlice = createSlice({
  name: 'client',
  initialState,
  reducers: {
    setGridBranchError: (state, action) => {
      state.GridBranchError[action.payload.rowId] = {
        name: action.payload.message,
      }
    },
    clearGridBranchError: (state, action) => {
      state.GridBranchError[action.payload] = {}
    },
    setGridCityError: (state, action) => {
      state.GridCityError[action.payload.rowId] = {
        name: action.payload.message,
      }
    },
    clearGridCityError: (state, action) => {
      state.GridCityError[action.payload] = {}
    },
    setGridStateError: (state, action) => {
      state.GridStateError[action.payload.rowId] = {
        name: action.payload.message,
      }
    },
    clearGridStateError: (state, action) => {
      state.GridStateError[action.payload] = {}
    },
    setGridZoneError: (state, action) => {
      state.GridZoneError[action.payload.rowId] = {
        name: action.payload.message,
      }
    },
    clearGridZoneError: (state, action) => {
      state.GridZoneError[action.payload] = {}
    },
    setGridCountryError: (state, action) => {
      state.GridCountryError[action.payload.rowId] = {
        name: action.payload.message,
      }
    },
    clearGridCountryError: (state, action) => {
      state.GridCountryError[action.payload] = {}
    },
    // ------
    setGridNameError: (state, action) => {
      state.GridNameError[action.payload.rowId] = {
        name: action.payload.message,
      }
    },
    clearGridNameError: (state, action) => {
      state.GridNameError[action.payload] = {}
    },
    setGridDesignationError: (state, action) => {
      state.GridDesignationError[action.payload.rowId] = {
        name: action.payload.message,
      }
    },
    clearGridDesignationError: (state, action) => {
      state.GridDesignationError[action.payload] = {}
    },
    setGridcontactnumberError: (state, action) => {
      state.GridcontactnumberError[action.payload.rowId] = {
        name: action.payload.message,
      }
    },
    clearGridcontactnumberError: (state, action) => {
      state.GridcontactnumberError[action.payload] = {}
    },
    setGridmailError: (state, action) => {
      state.GridmailError[action.payload.rowId] = {
        name: action.payload.message,
      }
    },
    clearGridmailError: (state, action) => {
      state.GridmailError[action.payload] = {}
    },
    setGridDefaultCheckError: (state, action) => {
      state.GridDefaultCheckError[action.payload.rowId] = {
        name: action.payload.message,
      }
    },
    clearGridDefaultCheckError: (state, action) => {
      state.GridDefaultCheckError[action.payload] = {}
    },
    setBillingTypeError: (state, action) => {
      state.BillingTypeError = { name: action.payload }
    },
    clearBillingTypeError: (state) => {
      state.BillingTypeError = {}
    },
    setClientTypeError: (state, action) => {
      state.ClientTypeError = { name: action.payload }
    },
    clearClientTypeError: (state) => {
      state.ClientTypeError = {}
    },
    setNameError: (state, action) => {
      state.NameError = { name: action.payload }
    },
    clearNameError: (state) => {
      state.NameError = {}
    },
    setRegisterofficeError: (state, action) => {
      state.RegisterofficeError = { name: action.payload }
    },
    clearRegisterofficeError: (state) => {
      state.RegisterofficeError = {}
    },
    setSlavendordateError: (state, action) => {
      state.SlavendordateError = { name: action.payload }
    },
    clearSlavendordateError: (state) => {
      state.SlavendordateError = {}
    },
    setSelectproductsError: (state, action) => {
      state.SelectproductsError = { name: action.payload }
    },
    clearSelectproductsError: (state) => {
      state.SelectproductsError = {}
    },
    setSlafromdateError: (state, action) => {
      state.SlafromdateError = { name: action.payload }
    },
    clearSlafromdateError: (state) => {
      state.SlafromdateError = {}
    },
    setSlatodateError: (state, action) => {
      state.SlatodateError = { name: action.payload }
    },
    clearSlatodateError: (state) => {
      state.SlatodateError = {}
    },
    setAdvancepaidError: (state, action) => {
      state.AdvancepaidError = { name: action.payload }
    },
    clearAdvancepaidError: (state) => {
      state.AdvancepaidError = {}
    },
    setAadhaarcopyError: (state, action) => {
      state.AadhaarcopyError = { name: action.payload }
    },
    clearAadhaarcopyError: (state) => {
      state.AadhaarcopyError = {}
    },
    setCityError: (state, action) => {
      state.CityError = { name: action.payload }
    },
    clearCityError: (state) => {
      state.CityError = {}
    },
    setStateError: (state, action) => {
      state.StateError = { name: action.payload }
    },
    clearStateError: (state) => {
      state.StateError = {}
    },
    setZoneError: (state, action) => {
      state.ZoneError = { name: action.payload }
    },
    clearZoneError: (state) => {
      state.ZoneError = {}
    },
    setPercaseError: (state, action) => {
      state.PercaseError = { name: action.payload }
    },
    clearPercaseError: (state) => {
      state.PercaseError = {}
    },
    setPincodeError: (state, action) => {
      state.PincodeError = { name: action.payload }
    },
    clearPincodeError: (state) => {
      state.PincodeError = {}
    },
    setGstnumberError: (state, action) => {
      state.GstnumberError = { name: action.payload }
    },
    clearGstnumberError: (state) => {
      state.GstnumberError = {}
    },
    setGstcopyError: (state, action) => {
      state.GstcopyError = { name: action.payload }
    },
    clearGstcopyError: (state) => {
      state.GstcopyError = {}
    },
    setPancardError: (state, action) => {
      state.PancardError = { name: action.payload }
    },
    clearPancardError: (state) => {
      state.PancardError = {}
    },
    setPancopyError: (state, action) => {
      state.PancopyError = { name: action.payload }
    },
    clearPancopyError: (state) => {
      state.PancopyError = {}
    },
    setGridgeolocationError: (state, action) => {
      state.GridgeolocationError = { name: action.payload }
    },
    clearGridgeolocationError: (state) => {
      state.GridgeolocationError = {}
    },
    setPermittedusersError: (state, action) => {
      state.PermittedusersError = { name: action.payload }
    },
    clearPermittedusersError: (state) => {
      state.PermittedusersError = {}
    },
    resetClientState: () => initialState,
  },
})

export const {
  setPancardError,
  clearPancardError,
  setPancopyError,
  clearPancopyError,
  setGridNameError,
  clearGridNameError,
  setGridcontactnumberError,
  clearGridcontactnumberError,
  setGridmailError,
  clearGridmailError,
  setGridgeolocationError,
  clearGridgeolocationError,
  setPermittedusersError,
  clearPermittedusersError,
  setGstcopyError,
  clearGstcopyError,
  setGstnumberError,
  clearGstnumberError,
  setPincodeError,
  clearPincodeError,
  setPercaseError,
  clearPercaseError,
  setZoneError,
  clearZoneError,
  setStateError,
  clearStateError,
  setCityError,
  clearCityError,
  setRegisterofficeError,
  clearRegisterofficeError,
  setSlavendordateError,
  clearSlavendordateError,
  setSlafromdateError,
  clearSlafromdateError,
  setSlatodateError,
  clearSlatodateError,
  setAdvancepaidError,
  clearAdvancepaidError,
  setAadhaarcopyError,
  clearAadhaarcopyError,
  setSelectproductsError,
  clearSelectproductsError,
  setNameError,
  clearNameError,
  setClientTypeError,
  clearClientTypeError,
  setBillingTypeError,
  clearBillingTypeError,
  setGridCityError,
  clearGridCityError,
  setGridStateError,
  clearGridStateError,
  setGridZoneError,
  clearGridZoneError,
  setGridCountryError,
  clearGridCountryError,
  setGridBranchError,
  clearGridBranchError,
  setGridDesignationError,
  clearGridDesignationError,
  GridDefaultCheckError,
  setGridDefaultCheckError,
  clearGridDefaultCheckError,
  resetClientState,
} = clientSlice.actions

export default clientSlice.reducer
