import React, { useState, lazy, Suspense } from 'react'
import { Routes, Route, BrowserRouter } from 'react-router-dom'
import AuthLayout from '../layout/AuthLayout'
import MainLayout from '../layout/MainLayout'
import LoadingIndicator from '../component/Loader'
import { ToastContainer } from 'react-toastify'

const AdminDashboard = lazy(() => import('../pages/main/icgms/Dashboard'))
const Client = lazy(() =>
  import('../pages/main/icgms/registration/client/Index')
)
const ClientCreate = lazy(() =>
  import('../pages/main/icgms/registration/client/Creation')
)
const ClientShow = lazy(() =>
  import('../pages/main/icgms/registration/client/Show')
)
const ClientEdit = lazy(() =>
  import('../pages/main/icgms/registration/client/Edit')
)
const ICGMS = lazy(() => import('../pages/main/icgms/registration/icgms/Index'))
const ICGMSCreate = lazy(() =>
  import('../pages/main/icgms/registration/icgms/Creation')
)
const ICGMSShow = lazy(() =>
  import('../pages/main/icgms/registration/icgms/Show')
)
const ICGMSEdit = lazy(() =>
  import('../pages/main/icgms/registration/icgms/Edit')
)
const InsuranceFinancier = lazy(() =>
  import('../pages/main/icgms/registration/insuranceFinancier/Index')
)
const InsuranceFinancierCreate = lazy(() =>
  import('../pages/main/icgms/registration/insuranceFinancier/Creation')
)
const InsuranceFinancierShow = lazy(() =>
  import('../pages/main/icgms/registration/insuranceFinancier/Show')
)
const InsuranceFinancierEdit = lazy(() =>
  import('../pages/main/icgms/registration/insuranceFinancier/Edit')
)
const Repairer = lazy(() =>
  import('../pages/main/icgms/registration/repairer/Index')
)
const RepairerCreate = lazy(() =>
  import('../pages/main/icgms/registration/repairer/Creation')
)
const RepairerShow = lazy(() =>
  import('../pages/main/icgms/registration/repairer/Show')
)
const RepairerEdit = lazy(() =>
  import('../pages/main/icgms/registration/repairer/Edit')
)
const RepairerMap = lazy(() =>
  import('../pages/main/icgms/registration/repairer/Mapped')
)
const RepairerReject = lazy(() =>
  import('../pages/main/icgms/registration/repairer/Reject')
)
const Surveyor = lazy(() =>
  import('../pages/main/icgms/registration/surveyor/Index')
)
const SurveyorCreate = lazy(() =>
  import('../pages/main/icgms/registration/surveyor/Creation')
)
const SurveyorShow = lazy(() =>
  import('../pages/main/icgms/registration/surveyor/Show')
)
const SurveyorEdit = lazy(() =>
  import('../pages/main/icgms/registration/surveyor/Edit')
)
const SurveyorMap = lazy(() =>
  import('../pages/main/icgms/registration/surveyor/Mapped')
)
const SurveyorReject = lazy(() =>
  import('../pages/main/icgms/registration/surveyor/Reject')
)
const Vehicle = lazy(() => import('../pages/main/icgms/master/vehicle/Index'))
const VehicleShow = lazy(() =>
  import('../pages/main/icgms/master/vehicle/Show')
)
const VehicleEdit = lazy(() =>
  import('../pages/main/icgms/master/vehicle/Edit')
)
const Location = lazy(() => import('../pages/main/icgms/master/location/Index'))
const Product = lazy(() => import('../pages/main/icgms/master/product/Index'))
const Insurer = lazy(() =>
  import('../pages/main/icgms/master/insurer/Creation')
)
const ProductShow = lazy(() =>
  import('../pages/main/icgms/master/product/Show')
)
const ProductEdit = lazy(() =>
  import('../pages/main/icgms/master/product/Edit')
)
const Billing = lazy(() => import('../pages/main/icgms/account/Bill'))
const Subscription = lazy(() =>
  import('../pages/main/icgms/account/Subscription')
)
const AdminSettings = lazy(() => import('../pages/main/icgms/Settings'))
const AdminReports = lazy(() => import('../pages/main/icgms/reports/Index'))
const Login = lazy(() => import('../pages/auth/Login'))
const Forgot = lazy(() => import('../pages/auth/Forgot'))
const Reset = lazy(() => import('../pages/auth/ResetPassword'))

// Client
const ClientLogin = lazy(() => import('../pages/auth/clientLogin'))
const ClientDashboard = lazy(() => import('../pages/main/client/Dashboard'))
const UserCreation = lazy(() =>
  import('../pages/main/client/registration/users/Creation')
)
const UserGrid = lazy(() =>
  import('../pages/main/client/registration/users/Grid')
)
const SurveyorCreation = lazy(() =>
  import('../pages/main/client/registration/surveyor/Creation')
)
const SurveyorGrid = lazy(() =>
  import('../pages/main/client/registration/surveyor/Grid')
)
const RepairerCreation = lazy(() =>
  import('../pages/main/client/registration/repairer/Creation')
)
const RepairerGrid = lazy(() =>
  import('../pages/main/client/registration/repairer/Grid')
)
const NewClaim = lazy(() => import('../pages/main/client/motorClaims/Newclaim'))
const ClaimGrid = lazy(() =>
  import(
    '../pages/main/client/motorClaims/claimIndimation/ClaimverificationGrid'
  )
)
const ClaimVerify = lazy(() =>
  import('../pages/main/client/motorClaims/claimIndimation/Claimverification')
)
const ClaimCorrection = lazy(() =>
  import('../pages/main/client/motorClaims/claimIndimation/ClaimverificationRe')
)
const ClaimFormQC = lazy(() =>
  import('../pages/main/client/motorClaims/claimIndimation/ClaimFormQC')
)
const ClaimReceivedFromInsurer = lazy(() =>
  import('../pages/main/client/motorClaims/claimIndimation/ReceivedFromInsurer')
)
const ClaimFormQCSurveyor = lazy(() =>
  import('../pages/main/client/motorClaims/claimIndimation/ClaimFormQCSurveyor')
)
const Qcrecorrction = lazy(() =>
  import('../pages/main/client/motorClaims/claimIndimation/QCrecorrection')
)
const SurveyorReport = lazy(() =>
  import('../pages/main/client/motorClaims/claimIndimation/SurveyorReport')
)
const SurveyorReportRepair = lazy(() =>
  import(
    '../pages/main/client/motorClaims/claimIndimation/SurveyorReportforRepair'
  )
)
const RepairerReport = lazy(() =>
  import('../pages/main/client/motorClaims/claimIndimation/RepairerReport')
)
const CustomerDetails = lazy(() =>
  import('../pages/main/client/motorClaims/claimIndimation/CustomerDetails')
)
const CustomerInfo = lazy(() =>
  import('../pages/main/client/motorClaims/claimIndimation/CustomerDet')
)
const IntimationClaim = lazy(() =>
  import('../pages/main/client/motorClaims/claimIndimation/IntimationClaim')
)
const ClaimInsurer = lazy(() =>
  import(
    '../pages/main/client/motorClaims/claimIndimation/ClaimFormQCClaimIntimate'
  )
)
const ClientSettings = lazy(() => import('../pages/main/client/Settings'))
const ClientReports = lazy(() => import('../pages/main/client/Reports'))
const CustomerPrint = lazy(() =>
  import(
    '../../src/pages/main/client/motorClaims/claimIndimation/print/PrintClaimform'
  )
)
const CustomerPrintIffco = lazy(() =>
  import('../../src/pages/main/client/motorClaims/claimIndimation/print/Iffco')
)
const IciciForm = lazy(() =>
  import(
    '../../src/pages/main/client/motorClaims/claimIndimation/print/IciciLomboard'
  )
)
const IffcoForm = lazy(() =>
  import('../../src/pages/main/client/motorClaims/claimIndimation/print/Iffco')
)
const NewIndiaForm = lazy(() =>
  import(
    '../../src/pages/main/client/motorClaims/claimIndimation/print/NewIndia'
  )
)
const NationalForm = lazy(() =>
  import(
    '../../src/pages/main/client/motorClaims/claimIndimation/print/National'
  )
)

export default () => {
  const [, setAuthenticated] = useState(false)
  return (
    <>
      <ToastContainer />
      <BrowserRouter>
        <Routes>
          {/* --Admin-- */}
          <Route
            path="/"
            element={<AuthLayout setAuthenticated={setAuthenticated} />}
          >
            <Route
              index
              path="/"
              element={
                <Suspense fallback={<LoadingIndicator />}>
                  <Login />
                </Suspense>
              }
            />
            <Route
              path="/forgot"
              element={
                <Suspense fallback={<LoadingIndicator />}>
                  <Forgot />
                </Suspense>
              }
            />
            <Route
              path="/reset/:ID"
              element={
                <Suspense fallback={<LoadingIndicator />}>
                  <Reset />
                </Suspense>
              }
            />
            <Route
              path="/customerform"
              element={
                <Suspense fallback={<LoadingIndicator />}>
                  <CustomerPrint />
                </Suspense>
              }
            />
          </Route>

          {/* --Client-- */}
          {/* <Route
            path="/client"
            element={<AuthLayout setAuthenticated={setAuthenticated} />}
          >
            <Route
              index
              path="/client"
              element={
                <Suspense fallback={<LoadingIndicator />}>
                  <ClientLogin />
                </Suspense>
              }
            />
            <Route
              path="/client/forgot"
              element={
                <Suspense fallback={<LoadingIndicator />}>
                  <Forgot />
                </Suspense>
              }
            />
            <Route
              path="/client/reset/:ID"
              element={
                <Suspense fallback={<LoadingIndicator />}>
                  <Reset />
                </Suspense>
              }
            />
            <Route
              path="/client/customerform"
              element={
                <Suspense fallback={<LoadingIndicator />}>
                  <CustomerPrint />
                </Suspense>
              }
            />
          </Route> */}

          {/* ------------ */}
          <Route path="/" element={<MainLayout />}>
            <Route path="/admin">
              <Route
                path="/admin/dashboard"
                element={
                  <Suspense fallback={<LoadingIndicator />}>
                    <AdminDashboard />
                  </Suspense>
                }
              />
              <Route
                path="/admin/client"
                element={
                  <Suspense fallback={<LoadingIndicator />}>
                    <Client />
                  </Suspense>
                }
              />
              <Route
                path="/admin/client/create"
                element={
                  <Suspense fallback={<LoadingIndicator />}>
                    <ClientCreate />
                  </Suspense>
                }
              />
              <Route
                path="/admin/client/show/:id"
                element={
                  <Suspense fallback={<LoadingIndicator />}>
                    <ClientShow />
                  </Suspense>
                }
              />
              <Route
                path="/admin/client/edit/:id"
                element={
                  <Suspense fallback={<LoadingIndicator />}>
                    <ClientEdit />
                  </Suspense>
                }
              />
              <Route
                path="/admin/icgms"
                element={
                  <Suspense fallback={<LoadingIndicator />}>
                    <ICGMS />
                  </Suspense>
                }
              />
              <Route
                path="/admin/icgms/create"
                element={
                  <Suspense fallback={<LoadingIndicator />}>
                    <ICGMSCreate />
                  </Suspense>
                }
              />
              <Route
                path="/admin/icgms/show/:id"
                element={
                  <Suspense fallback={<LoadingIndicator />}>
                    <ICGMSShow />
                  </Suspense>
                }
              />
              <Route
                path="/admin/icgms/edit/:id"
                element={
                  <Suspense fallback={<LoadingIndicator />}>
                    <ICGMSEdit />
                  </Suspense>
                }
              />
              <Route
                path="/admin/insurancefinancier"
                element={
                  <Suspense fallback={<LoadingIndicator />}>
                    <InsuranceFinancier />
                  </Suspense>
                }
              />
              <Route
                path="/admin/insurancefinancier/create"
                element={
                  <Suspense fallback={<LoadingIndicator />}>
                    <InsuranceFinancierCreate />
                  </Suspense>
                }
              />
              <Route
                path="/admin/insurancefinancier/show/:id"
                element={
                  <Suspense fallback={<LoadingIndicator />}>
                    <InsuranceFinancierShow />
                  </Suspense>
                }
              />
              <Route
                path="/admin/insurancefinancier/edit/:id"
                element={
                  <Suspense fallback={<LoadingIndicator />}>
                    <InsuranceFinancierEdit />
                  </Suspense>
                }
              />
              <Route
                path="/admin/repairer"
                element={
                  <Suspense fallback={<LoadingIndicator />}>
                    <Repairer />
                  </Suspense>
                }
              />
              <Route
                path="/admin/repairer/create"
                element={
                  <Suspense fallback={<LoadingIndicator />}>
                    <RepairerCreate />
                  </Suspense>
                }
              />
              <Route
                path="/admin/repairer/show/:id"
                element={
                  <Suspense fallback={<LoadingIndicator />}>
                    <RepairerShow />
                  </Suspense>
                }
              />
              <Route
                path="/admin/repairer/edit/:id"
                element={
                  <Suspense fallback={<LoadingIndicator />}>
                    <RepairerEdit />
                  </Suspense>
                }
              />
              <Route
                path="/admin/repairer/map"
                element={
                  <Suspense fallback={<LoadingIndicator />}>
                    <RepairerMap />
                  </Suspense>
                }
              />
              <Route
                path="/admin/repairer/reject"
                element={
                  <Suspense fallback={<LoadingIndicator />}>
                    <RepairerReject />
                  </Suspense>
                }
              />
              <Route
                path="/admin/surveyor"
                element={
                  <Suspense fallback={<LoadingIndicator />}>
                    <Surveyor />
                  </Suspense>
                }
              />
              <Route
                path="/admin/surveyor/create"
                element={
                  <Suspense fallback={<LoadingIndicator />}>
                    <SurveyorCreate />
                  </Suspense>
                }
              />
              <Route
                path="/admin/surveyor/show/:id"
                element={
                  <Suspense fallback={<LoadingIndicator />}>
                    <SurveyorShow />
                  </Suspense>
                }
              />
              <Route
                path="/admin/surveyor/edit/:id"
                element={
                  <Suspense fallback={<LoadingIndicator />}>
                    <SurveyorEdit />
                  </Suspense>
                }
              />
              <Route
                path="/admin/surveyor/map"
                element={
                  <Suspense fallback={<LoadingIndicator />}>
                    <SurveyorMap />
                  </Suspense>
                }
              />
              <Route
                path="/admin/surveyor/reject"
                element={
                  <Suspense fallback={<LoadingIndicator />}>
                    <SurveyorReject />
                  </Suspense>
                }
              />
              <Route
                path="/admin/vehicle"
                element={
                  <Suspense fallback={<LoadingIndicator />}>
                    <Vehicle />
                  </Suspense>
                }
              />
              <Route
                path="/admin/vehicle/show"
                element={
                  <Suspense fallback={<LoadingIndicator />}>
                    <VehicleShow />
                  </Suspense>
                }
              />
              <Route
                path="/admin/vehicle/edit"
                element={
                  <Suspense fallback={<LoadingIndicator />}>
                    <VehicleEdit />
                  </Suspense>
                }
              />
              <Route
                path="/admin/location"
                element={
                  <Suspense fallback={<LoadingIndicator />}>
                    <Location />
                  </Suspense>
                }
              />
              <Route
                path="/admin/product"
                element={
                  <Suspense fallback={<LoadingIndicator />}>
                    <Product />
                  </Suspense>
                }
              />
              <Route
                path="/admin/insurer"
                element={
                  <Suspense fallback={<LoadingIndicator />}>
                    <Insurer />
                  </Suspense>
                }
              />
              <Route
                path="/admin/product/show"
                element={
                  <Suspense fallback={<LoadingIndicator />}>
                    <ProductShow />
                  </Suspense>
                }
              />
              <Route
                path="/admin/product/edit"
                element={
                  <Suspense fallback={<LoadingIndicator />}>
                    <ProductEdit />
                  </Suspense>
                }
              />
              <Route
                path="/admin/account/billing"
                element={
                  <Suspense fallback={<LoadingIndicator />}>
                    <Billing />
                  </Suspense>
                }
              />
              <Route
                path="/admin/account/subscription"
                element={
                  <Suspense fallback={<LoadingIndicator />}>
                    <Subscription />
                  </Suspense>
                }
              />
              <Route
                path="/admin/reports"
                element={
                  <Suspense fallback={<LoadingIndicator />}>
                    <AdminReports />
                  </Suspense>
                }
              />
              <Route
                path="/admin/settings"
                element={
                  <Suspense fallback={<LoadingIndicator />}>
                    <AdminSettings />
                  </Suspense>
                }
              />
            </Route>
            <Route path="/client">
              <Route
                path="/client/client/dashboard"
                element={
                  <Suspense fallback={<LoadingIndicator />}>
                    <ClientDashboard />
                  </Suspense>
                }
              />
              <Route
                path="/client/client/user/creation"
                element={
                  <Suspense fallback={<LoadingIndicator />}>
                    <UserCreation />
                  </Suspense>
                }
              />
              <Route
                path="/client/client/user/creation"
                element={
                  <Suspense fallback={<LoadingIndicator />}>
                    <UserCreation />
                  </Suspense>
                }
              />
              <Route
                path="/client/client/user/grid"
                element={
                  <Suspense fallback={<LoadingIndicator />}>
                    <UserGrid />
                  </Suspense>
                }
              />
              <Route
                path="/client/client/surveyor/creation"
                element={
                  <Suspense fallback={<LoadingIndicator />}>
                    <SurveyorCreation />
                  </Suspense>
                }
              />
              <Route
                path="/client/client/surveyor/grid"
                element={
                  <Suspense fallback={<LoadingIndicator />}>
                    <SurveyorGrid />
                  </Suspense>
                }
              />
              <Route
                path="/client/client/repairer/creation"
                element={
                  <Suspense fallback={<LoadingIndicator />}>
                    <RepairerCreation />
                  </Suspense>
                }
              />
              <Route
                path="/client/client/repairer/grid"
                element={
                  <Suspense fallback={<LoadingIndicator />}>
                    <RepairerGrid />
                  </Suspense>
                }
              />
              <Route
                path="/client/client/newclaim/creation"
                element={
                  <Suspense fallback={<LoadingIndicator />}>
                    <NewClaim />
                  </Suspense>
                }
              />
              <Route
                path="/client/client/claimverify/grid"
                element={
                  <Suspense fallback={<LoadingIndicator />}>
                    <ClaimGrid />
                  </Suspense>
                }
              />
              <Route
                path="/client/client/claimverify/progress"
                element={
                  <Suspense fallback={<LoadingIndicator />}>
                    <ClaimVerify />
                  </Suspense>
                }
              />
              <Route
                path="/client/client/claimverify/recorrection"
                element={
                  <Suspense fallback={<LoadingIndicator />}>
                    <ClaimCorrection />
                  </Suspense>
                }
              />
              <Route
                path="/client/client/claimverify/claimqc"
                element={
                  <Suspense fallback={<LoadingIndicator />}>
                    <ClaimFormQC />
                  </Suspense>
                }
              />
              <Route
                path="/client/client/claimverify/receivedfrominsurer"
                element={
                  <Suspense fallback={<LoadingIndicator />}>
                    <ClaimReceivedFromInsurer />
                  </Suspense>
                }
              />
              <Route
                path="/client/client/claimverify/claimqcsurveyor"
                element={
                  <Suspense fallback={<LoadingIndicator />}>
                    <ClaimFormQCSurveyor />
                  </Suspense>
                }
              />
              <Route
                path="/client/client/claimverify/surveyorreport"
                element={
                  <Suspense fallback={<LoadingIndicator />}>
                    <SurveyorReport />
                  </Suspense>
                }
              />
              <Route
                path="/client/client/claimverify/surveyorforrepair"
                element={
                  <Suspense fallback={<LoadingIndicator />}>
                    <SurveyorReportRepair />
                  </Suspense>
                }
              />
              <Route
                path="/client/client/claimverify/repairerreport"
                element={
                  <Suspense fallback={<LoadingIndicator />}>
                    <RepairerReport />
                  </Suspense>
                }
              />
              <Route
                path="/client/client/claimverify/customerdetails"
                element={
                  <Suspense fallback={<LoadingIndicator />}>
                    <CustomerDetails />
                  </Suspense>
                }
              />
              <Route
                path="/client/client/claimverify/customerinfo"
                element={
                  <Suspense fallback={<LoadingIndicator />}>
                    <CustomerInfo />
                  </Suspense>
                }
              />
              <Route
                path="/client/client/claimverify/claiminsurer"
                element={
                  <Suspense fallback={<LoadingIndicator />}>
                    <ClaimInsurer />
                  </Suspense>
                }
              />
              <Route
                path="/client/client/reports"
                element={
                  <Suspense fallback={<LoadingIndicator />}>
                    <ClientReports />
                  </Suspense>
                }
              />
              <Route
                path="/client/client/settings"
                element={
                  <Suspense fallback={<LoadingIndicator />}>
                    <ClientSettings />
                  </Suspense>
                }
              />
              <Route
                path="/client/client/claimverify/qcrecorrection"
                element={
                  <Suspense fallback={<LoadingIndicator />}>
                    <Qcrecorrction />
                  </Suspense>
                }
              />
            </Route>
          </Route>
          {/* ---- intimation */}
          <Route
            path="/client/client/claimverify/intimation/:id"
            element={
              <Suspense fallback={<LoadingIndicator />}>
                <IntimationClaim />
              </Suspense>
            }
          />
          <Route
            path="/client/client/claimverify/print"
            element={
              <Suspense fallback={<LoadingIndicator />}>
                <CustomerPrint />
              </Suspense>
            }
          />
          <Route
            path="/client/client/iciciform"
            element={
              <Suspense fallback={<LoadingIndicator />}>
                <IciciForm />
              </Suspense>
            }
          />
          <Route
            path="/client/client/iffcoform"
            element={
              <Suspense fallback={<LoadingIndicator />}>
                <IffcoForm />
              </Suspense>
            }
          />
          <Route
            path="/client/client/newindiaform"
            element={
              <Suspense fallback={<LoadingIndicator />}>
                <NewIndiaForm />
              </Suspense>
            }
          />
          <Route
            path="/client/client/nationalform"
            element={
              <Suspense fallback={<LoadingIndicator />}>
                <NationalForm />
              </Suspense>
            }
          />
          <Route path="*" exact={true} element={404} />
        </Routes>
      </BrowserRouter>
    </>
  )
}
